import React, { useState } from 'react';


function encode(data) {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&')
}

function Form() {
    const [state, setState] = useState({})
    const [status, setStatus] = useState({ status: '' })

    const handleChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value })
    }

    function submitForm(e) {
        e.preventDefault()
        const form = e.target
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...state,
            }),
        })
            .then(() => {
            setStatus('success')
            setState({})
            })
            .catch((error) => alert(error))
    }

    return (
        <form
            name="contact"
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={(e) => submitForm(e)}
            className="contact-form"
        >
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input id="form-name" type="hidden" name="form-name" value="contact" />
            <p hidden>
                <label>
                    Don’t fill this out: <input id="bot-field" name="bot-field" onChange={(e) => handleChange(e)} />
                </label>
            </p>
            <div className="field">
                <label htmlFor="name" className="label">Name
                <div className="control">
                    <input className="input" id="name" type="text" name="name" placeholder="Name" onChange={(e) => handleChange(e)} />
                </div>
                </label>
            </div>
            <div className="field">
                <label htmlFor="email" className="label">Email</label>
                <div className="control">
                    <input className="input" id="email" type="email" name="email" placeholder="Email" onChange={(e) => handleChange(e)} />
                </div>
            </div>
            <div className="field">
                <label htmlFor="message" className="label">Message</label>
                <div className="control">
                    <textarea id="message" className="textarea" placeholder="Message" name="message" onChange={(e) => handleChange(e)}></textarea>
                </div>
            </div>

            <div className="control">
                <button className="button  tab-buttons  button-color" type="submit">SUBMIT</button>
            </div>
            {
                status === 'success'?          <p className="success"> Thankyou  your form has been submitted</p> : null
            }
   
        </form>
    );
}

export default Form;


