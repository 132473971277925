import React from "react"
import Hero from '../components/Hero';
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import { graphql } from 'gatsby'
import Form from '../components/Form';
import Description from "../components/HomePage/Decriptions";

const AboutPage = ({data}) => {
   return ( 
    <Layout>
        <SEO title="About Us"  url={`/contact`} />
        <Hero
            title="Contact Us"
            techStack=""
        />
        
        <Description description="Contact us for submissions, collabs or any slutty pictures of your own (food only please). " />
        <div className="container flex-center">
        <Form />
        </div>

    </Layout>
   )}

export default AboutPage

export const query = graphql`
query{
    allContentfulContactPage {
        edges {
          node {
            id
            title
          }
        }
  }
}`


